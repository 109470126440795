import Routes from "./routes/routes";
import './index.css' 
import React from "react";

//24/7/24 change to be done -this initial setup
//import routes in router file
function App() {
  return (
     <Routes/>
  );
}

export default App;
