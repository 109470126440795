import { createContext, useEffect, useReducer } from "react";
import { isTokenExpired } from "../utils/jwtUtils";

export const AuthContext = createContext()

export const authReducer = (state,action)=>{
    switch(action.type){
        case 'LOGIN':
            return {admin:action.payload}
        case 'LOGOUT':
            return {admin:null}
        default:
            return state
    }
}

export const AuthContextProvider = ({children}) =>{
    const [state,dispatch] = useReducer(authReducer,{
        admin:null
    })
    useEffect(()=>{
        const admin = JSON.parse(localStorage.getItem('admin'))
        if (admin) {
            // Check if the token is expired
            if (isTokenExpired(admin.token)) {
                // Remove the admin data from localStorage and dispatch logout
                localStorage.removeItem('admin');
                dispatch({ type: 'LOGOUT' });
            } else {
                // If the token is still valid, dispatch login
                dispatch({ type: 'LOGIN', payload: admin });
            }
        }
    },[])
    // console.log('AuthContext state',state)
    return(
        <AuthContext.Provider value={{...state,dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}